import React from "react";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import {useCookies} from "react-cookie";
import Utils from "../../commons/Utils/Utils";
import {Flex} from "@aws-amplify/ui-react";
import EiaTitle from "../../components/EiaTitle";

const whiteTextStyle = {
    color: 'white',
};

const isSmallScreen = Utils.isSmallScreen();
const initialView = isSmallScreen ? "dayGridTwoDay" : "dayGridMonth";

const customView = isSmallScreen ? {
    dayGridTwoDay: {
        type: 'dayGrid',
        duration: {days: 2}
    }
} : null

const D4Calendar = function (props) {

    const [cookies, setCookies, removeCookies] = useCookies(['darkMode']);
    const darkMode = cookies.darkMode === undefined ? true : cookies.darkMode;

    return (
        <Flex direction={"column"} >
            <EiaTitle title={"D4 Calendar"} lastUpdated={"2025/01/19"}/>
            <div style={darkMode ? whiteTextStyle : null}>
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView={initialView}
                    views={customView}
                    sx={{
                        color: 'white',
                    }}
                    events={[
                        {
                            title: 'Season 8',
                            start: '2025-04-15',
                            end: '2025-07-15',
                            color: 'red'
                        },
                        {
                            title: 'Lunar Awakening',
                            start: '2025-02-04',
                            end: '2025-02-18',
                            color: 'purple'
                        },
                        {
                            title: 'Season 7',
                            start: '2025-01-21',
                            end: '2025-04-15',
                            color: 'green'
                        },
                        {
                            title: 'Season 7 PTR 2.1',
                            start: '2024-12-03',
                            end: '2024-12-10',
                            color: 'orange'
                        },
                        {
                            title: 'Patch 2.0.5',
                            start: '2024-11-19',
                            end: '2024-11-19',
                        },
                        {
                            title: 'Campfire: Midseason',
                            start: '2024-11-12',
                            end: '2024-11-12',
                        },
                        {
                            title: 'Patch 2.0.4',
                            start: '2024-10-29',
                            end: '2024-10-29',
                        },
                        {
                            title: 'Macabre Celebration',
                            start: '2024-10-29',
                            end: '2024-11-05',
                            color: 'purple'
                        },
                        {
                            title: 'Patch 2.0.3',
                            start: '2024-10-17',
                            end: '2024-10-17',
                        },
                        {
                            title: 'Season 6',
                            start: '2024-10-08',
                            end: '2025-01-21',
                            color: 'red'
                        },
                        {
                            title: 'Release: Diablo 4 Vessel of Hatred',
                            start: '2024-10-08',
                            end: '2024-10-08',
                        },
                        {
                            title: '2.0 PTR',
                            start: '2024-09-04',
                            end: '2024-09-11',
                            color: 'orange'
                        },
                        {
                            title: 'Patch 1.5.2',
                            start: '2024-09-03',
                            end: '2024-09-03',
                        },
                        {
                            title: 'March of the Goblins',
                            start: '2024-08-27',
                            end: '2024-09-03',
                            color: 'purple'
                        },
                        {
                            title: 'Campfire: 2.0 PTR',
                            start: '2024-08-29',
                            end: '2024-08-29',
                        },
                        {
                            title: 'Mothers Blessing',
                            start: '2024-08-20',
                            end: '2024-08-28',
                            color: 'purple'
                        },
                        {
                            title: 'Patch 1.5.1',
                            start: '2024-08-20',
                            end: '2024-08-20',
                        },
                        {
                            title: 'Campfire: Season 5',
                            start: '2024-08-01',
                            end: '2024-08-01',
                        },
                        {
                            title: 'Season 5',
                            start: '2024-08-06',
                            end: '2024-10-08',
                            color: 'green'
                        },
                        {
                            title: 'Spiritborn Class Reveal',
                            start: '2024-07-18',
                            end: '2024-07-18',
                        },
                        {
                            title: 'Patch 1.4.4',
                            start: '2024-07-10',
                            end: '2024-07-10',
                        },
                        {
                            title: 'Season 5 PTR',
                            start: '2024-06-26',
                            end: '2024-07-02',
                            color: 'orange'
                        },
                        {
                            title: 'Campfire: Season 5 & PTR',
                            start: '2024-06-21',
                            end: '2024-06-21',
                        },
                        {
                            title: 'Patch 1.4.3',
                            start: '2024-06-17',
                            end: '2024-06-17',
                        },
                        {
                            title: 'Patch 1.4.2',
                            start: '2024-06-09',
                            end: '2024-06-09',
                        },
                        {
                            title: 'Mothers Blessing',
                            start: '2024-06-06',
                            end: '2024-06-21',
                            color: 'green'
                        },
                        {
                            title: 'March of the Goblins',
                            start: '2024-06-06',
                            end: '2024-06-14',
                            color: 'purple'
                        },
                        {
                            title: 'Season 4',
                            start: '2024-05-14',
                            end: '2024-08-06',
                            color: 'red'
                        },
                        {
                            title: 'Campfire: Season 4 Loot Reborn',
                            start: '2024-05-02',
                            end: '2024-05-02',
                        },
                        {
                            title: 'Season 4 PTR',
                            start: '2024-04-02',
                            end: '2024-04-09',
                            color: 'orange'
                        },
                        {
                            title: 'Diablo 4 joins Game Pass',
                            start: '2024-03-28',
                            end: '2024-03-28',
                        },
                        {
                            title: 'Mothers Blessing',
                            start: '2024-03-26',
                            end: '2024-04-02',
                            color: 'purple'
                        },
                        {
                            title: 'Patch 1.3.5 Raytracing',
                            start: '2024-03-26',
                            end: '2024-03-26',
                        },
                        {
                            title: 'Campfire: Season 4 & PTR',
                            start: '2024-03-20',
                            end: '2024-03-20',
                        },
                        {
                            title: 'Patch 1.3.4 Bug Fixes',
                            start: '2024-03-12',
                            end: '2024-03-12',
                        },
                        {
                            title: 'Patch 1.3.3 Gauntlet',
                            start: '2024-03-05',
                            end: '2024-03-05',
                        },
                        {
                            title: 'Campfire: Trials and Gauntlet',
                            start: '2024-02-29',
                            end: '2024-02-29',
                        },
                        {
                            title: 'Diablo 4 Release',
                            start: '2023-06-06',
                            end: '2023-06-06',
                        },
                        {
                            title: 'Season 1',
                            start: '2023-07-20',
                            end: '2023-10-17',
                            color: 'green'
                        },
                        {
                            title: 'Season 2',
                            start: '2023-10-17',
                            end: '2024-01-23',
                            color: 'red'
                        },
                        {
                            title: 'Midwinter Blight',
                            start: '2023-12-12',
                            end: '2024-01-02',
                            color: 'purple'
                        },
                        {
                            title: 'Season 3',
                            start: '2024-01-23',
                            end: '2024-05-14',
                            color: 'green'
                        },
                        {
                            title: 'Patch 1.3.0 Season 3',
                            start: '2024-01-23',
                            end: '2024-01-23',
                        },
                        {
                            title: 'Patch 1.3.0a',
                            start: '2024-01-26',
                            end: '2024-01-26',
                        },
                        {
                            title: 'Patch 1.3.1',
                            start: '2024-02-01',
                            end: '2024-02-01',
                        },
                        {
                            title: 'Lunar Awakening',
                            start: '2024-02-06',
                            end: '2024-02-20',
                            color: 'purple'
                        },
                        {
                            title: 'Patch 1.3.2 Uber Unique Crafting',
                            start: '2024-02-13',
                            end: '2024-02-13',
                        }
                    ]}
                    firstDay={1}
                />
            </div>
        </Flex>
    );
};
export default D4Calendar;