import React from "react";
import {Divider, Flex, Link, Text} from "@aws-amplify/ui-react";
import EiaButton from "../components/EiaButton";
import LegalNotice from "../components/drawer/LegalNotice";
import Utils from "../commons/Utils/Utils";
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import * as Navigation from "../commons/enums/Navigation";

const isSmallScreen = Utils.isSmallScreen();
const direction = isSmallScreen ? "column" : "row";

const MainPageFooter = function () {

    const navigateWithRouter = useNavigate();

    const navigate=function(value) {
        if(isSmallScreen) {
            Utils.scrollToStickyNavigationBar();
        }

        navigateWithRouter("/" + value);
    }

    const [cookies, setCookies, removeCookies] = useCookies(['darkMode']);
    const darkMode = cookies.darkMode === undefined ? true : cookies.darkMode;
    const textColor = darkMode ? 'black' : 'white';

    return (
        <Flex id="page-bottom" direction={"column"}>
            <Divider/>
            <Flex
                direction={direction}
                justifyContent="right"
            >
                <Flex direction={"column"}>
                    <Text >This site is fan-made and not affiliated with Blizzard Entertainment, Inc. in any way.</Text>
                    <Text >Diablo and Blizzard Entertainment are trademarks or registered trademarks of Blizzard Entertainment, Inc., in the U.S. and/or other countries.</Text>
                </Flex>
                <EiaButton isFullWidth={isSmallScreen}>
                    <Link
                        href="https://ko-fi.com/easyitemtrading"
                        color={textColor}
                        isExternal={true}
                    >
                        Support Me
                    </Link>
                </EiaButton>
                <Flex direction={"column"}>
                    <EiaButton isFullWidth={true}>
                        <Link
                            href="https://discord.gg/RR3RwkyGGQ"
                            color={textColor}
                            isExternal={true}
                        >
                            Discord
                        </Link>
                    </EiaButton>
                    <EiaButton isFullWidth={true}>
                        <Link
                            href="https://youtube.com/@hasgareasyitemtrading?si=KwlPEEXpAUnnYmVY"
                            color={textColor}
                            isExternal={true}
                        >
                            Youtube
                        </Link>
                    </EiaButton>
                    <EiaButton isFullWidth={true}>
                        <Link
                            href="https://www.twitch.tv/dolphde"
                            color={textColor}
                            isExternal={true}
                        >
                            Twitch Supporter
                        </Link>
                    </EiaButton>
                </Flex>
                <Flex direction={"column"}>
                    <EiaButton isFullWidth={true} onClick={() => navigate(Navigation.FAQ)}>FAQ</EiaButton>
                    <EiaButton isFullWidth={true}>
                        <Link
                            href="mailto:easyitemtrading@gmx.de?subject=EasyItemTrading Contact"
                            color={textColor}
                        >
                            Contact
                        </Link>
                    </EiaButton>
                    <EiaButton isFullWidth={true}>
                        <Link
                            href="mailto:easyitemtrading@gmx.de?subject=EasyItemTrading Bug Report"
                            color={textColor}
                        >
                            Bug Report
                        </Link>
                    </EiaButton>
                </Flex>
                <Flex direction={"column"}>
                    <EiaButton isFullWidth={true} onClick={() => navigate(Navigation.USER_PRIVACY_POLICY)}>User Privacy Notice</EiaButton>
                    <LegalNotice/>
                </Flex>
                <Text>WebApp Version 1.5.5</Text>
            </Flex>
        </Flex>
    );
}

export default MainPageFooter;