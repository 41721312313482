import {Link} from "@aws-amplify/ui-react";

const getLink = function (amount, name, link) {
    const label = amount ? amount + " x " + name : name;

    return <Link
        href={link}
        color="#F4D03F"
        isExternal={true}
    >
        {label}
    </Link>
}



// summoning

const pincushionedDollName = "Pincushioned Doll"
export const pincushionedDoll = function (amount) {
    return getLink(amount, pincushionedDollName, "https://www.wowhead.com/diablo-4/item/pincushioned-doll-1812685");
}

const sandScorchedShacklesName = "Sandscorched Shackles"
export const sandScorchedShackles = function (amount) {
    return getLink(amount, sandScorchedShacklesName, "https://www.wowhead.com/diablo-4/item/sandscorched-shackles-1810144");
}

const stygianStoneName = "Stygian Stone"
export const stygianStone = function (amount) {
    return getLink(amount, stygianStoneName, "https://www.wowhead.com/diablo-4/item/stygian-stone-1850845");
}

const igneousCoreName = "Igneous Core";
export const igneousCore = function (amount) {
    return getLink(amount, igneousCoreName, "https://www.wowhead.com/diablo-4/item/igneous-core-1601829");
}

const shardOfAgonyName = "Shard of Agony";
export const shardOfAgony = function (amount) {
    return getLink(amount, shardOfAgonyName, "https://www.wowhead.com/diablo-4/item/shard-of-agony-1524924");
}

export const distilledFear = function (amount) {
    return getLink(amount, "Distilled Fear", "https://www.wowhead.com/diablo-4/item/distilled-fear-1518053");
}

export const livingSteel = function (amount) {
    return getLink(amount, "Living Steel", "https://www.wowhead.com/diablo-4/item/living-steel-1502128");
}

export const malignantHeart = function (amount) {
    return getLink(amount, "Malignant Heart", "https://www.wowhead.com/diablo-4/item/malignant-heart-1489420");
}

export const exquisiteBlood = function (amount) {
    return getLink(amount, "Exquisite Blood", "https://www.wowhead.com/diablo-4/item/exquisite-blood-1522891");
}

export const blackenedFemur = function (amount) {
    return getLink(amount, "Blackened Femur", "https://www.wowhead.com/diablo-4/item/blackened-femur-1489422");
}

export const mucusSlickEgg = function (amount) {
    return getLink(amount, "Mucus-Slick Egg", "https://www.wowhead.com/diablo-4/item/mucus-slick-egg-1524917");
}

export const tremblingHand = function (amount) {
    return getLink(amount, "Trembling Hand", "https://www.wowhead.com/diablo-4/item/trembling-hand-1489411");
}

export const gurglingHead = function (amount) {
    return getLink(amount, "Gurgling Head", "https://www.wowhead.com/diablo-4/item/gurgling-head-1489418");
}

// world tiers

const worldTier3Name = "T3";
export const worldTier3 = function (amount) {
    return getLink(amount, worldTier3Name, "https://www.wowhead.com/diablo-4/guide/gameplay/world-tiers-difficulty#tier-3");
}

const worldTier3And4Name = "T3+T4";
export const worldTier3And4 = function (amount) {
    return getLink(amount, worldTier3And4Name, "https://www.wowhead.com/diablo-4/guide/gameplay/world-tiers-difficulty#tier-3");
}

const worldTier4Name = "T4";
export const worldTier4 = function (amount) {
    return getLink(amount, worldTier4Name, "https://www.wowhead.com/diablo-4/guide/gameplay/world-tiers-difficulty#tier-4");
}

// -------------------------------------- elixirs --------------------------------------

const elixirOfHolyBoltsName = "Elixir of Holy Bolts";
export const elixirOfHolyBolts = function (amount) {
    return getLink(amount, elixirOfHolyBoltsName, "https://www.wowhead.com/diablo-4/item/elixir-of-holy-bolts-1856350");
}

const profaneMindcageName = "Profane Mindcage"
export const profaneMindcage = function (amount) {
    return getLink(amount, profaneMindcageName, "https://www.wowhead.com/diablo-4/item/profane-mindcage-1882910");
}

const elixirOfMomentumName = "Elixir of Momentum"
export const elixirOfMomentum = function (amount) {
    return getLink(amount, elixirOfMomentumName, "https://www.wowhead.com/diablo-4/item/elixir-of-momentum-1856252");
}

const abyssalScrollName = "Abyssal Scroll"
export const abyssalScroll = function(amount) {
    return getLink(amount, abyssalScrollName, "https://www.wowhead.com/diablo-4/item/abyssal-scroll-1971857");
}

const draughtOfWhispersName = "Draught of Whispers"
export const draughtOfWhispers = function(amount) {
    return getLink(amount, draughtOfWhispersName, "https://www.wowhead.com/diablo-4/item/draught-of-whispers-2145204");
}

const seethingOpalOfTormentName = "Seething Opal of Torment"
export const seethingOpalOfTorment = function(amount) {
    return getLink(amount, seethingOpalOfTormentName, "https://www.wowhead.com/diablo-4/item/seething-opal-of-torment-2073002");
}

const elixirOfHollowUnmakingName = "Elixir of Hollow Unmaking"
export const elixirOfHollowUnmaking = function(amount) {
    return getLink(amount, elixirOfHollowUnmakingName, "https://www.wowhead.com/diablo-4/item/elixir-of-hollow-unmaking-1991376");
}


export const getLinkOrNull = function (itemName) {
    switch (itemName) {
        //materials
        case pincushionedDollName:
            return pincushionedDoll();
        case sandScorchedShacklesName:
            return sandScorchedShackles();
        case stygianStoneName:
            return stygianStone();
        case igneousCoreName:
            return igneousCore();
        case shardOfAgonyName:
            return shardOfAgony();
        case "Distilled Fear":
            return distilledFear();
        case "Living Steel":
            return livingSteel();
        case "Malignant Heart":
            return malignantHeart();
        case "Exquisite Blood":
            return exquisiteBlood();
        case "Blackened Femur":
            return blackenedFemur();
        case "Mucus-Slick Egg":
            return mucusSlickEgg();
        case "Trembling Hand":
            return tremblingHand();
        case "Gurgling Head":
            return gurglingHead();

        //world tiers
        case worldTier3Name:
            return worldTier3();
        case worldTier3And4Name:
            return worldTier3And4();
        case worldTier4Name:
            return worldTier4();

        //elixirs
        case elixirOfHolyBoltsName:
            return elixirOfHolyBolts();
        case profaneMindcageName:
            return profaneMindcage();
        case elixirOfMomentumName:
            return elixirOfMomentum();
        case draughtOfWhispersName:
            return draughtOfWhispers();
        case seethingOpalOfTormentName:
            return seethingOpalOfTorment();
        case elixirOfHollowUnmakingName:
            return elixirOfHollowUnmaking();

        default:
            return null;
    }
}

