import {Link} from "@aws-amplify/ui-react";
import React from "react";

const getLink = function (amount, name, link) {
    const label = amount ? amount + " x " + name : name;

    return <Link
        key={name}
        href={link}
        color="#F4D03F"
        isExternal={true}
    >
        {label}
    </Link>
}



export const zanRune = function (amount) {
    return getLink(amount, "Zan", "https://www.wowhead.com/diablo-4/item/zan-1968550");
}

export const zecRune = function (amount) {
    return getLink(amount, "Zec", "https://www.wowhead.com/diablo-4/item/zec-1968672");
}

export const igniRune = function (amount) {
    return getLink(amount, "Igni", "https://www.wowhead.com/diablo-4/item/igni-2165977");
}

export const naguRune = function (amount) {
    return getLink(amount, "Nagu", "https://www.wowhead.com/diablo-4/item/nagu-2155997");
}

export const kaaRune = function (amount) {
    return getLink(amount, "Kaa", "https://www.wowhead.com/diablo-4/item/kaa-2081285");
}

export const uraRune = function (amount) {
    return getLink(amount, "Ura", "https://www.wowhead.com/diablo-4/item/ura-2040966");
}

export const chacRune = function (amount) {
    return getLink(amount, "Chac", "https://www.wowhead.com/diablo-4/item/chac-2166985");
}

export const metRune = function (amount) {
    return getLink(amount, "Met", "https://www.wowhead.com/diablo-4/item/met-2004724");
}

export const onoRune = function (amount) {
    return getLink(amount, "Ono", "https://www.wowhead.com/diablo-4/item/ono-2006151");
}